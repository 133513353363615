import React from "react"
import { Layout, Title, Card, Cards } from "../components/index"
import { Helmet } from "react-helmet"
import Favicon from "../img/favicon3.png"

export default () => (
  <div className="kontakt">
    <Layout>
      <Helmet>
        <title>Video prezentacije – Nena Lončar vam predstavlja!</title>
        <meta
          name="title"
          content="Video prezentacije – Nena Lončar vam predstavlja!"
        />
        <meta
          name="description"
          content="Nena Lončar vam predstavlja video prezentacije."
        />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>
      <Title
        title="Video prezentacije"
        subtitle="Nena Lončar vam predstavlja!"
      ></Title>
      <Cards>
        <Card
          title="Video prezentacije"
          subtitle="Na ovoj poveznici mogu se pogledati video prezentacije svih knjiga:"
          link="https://www.facebook.com/Mojeknjige10/"
        />
      </Cards>
    </Layout>
  </div>
)
